@media screen and (max-width: 400px) {
  #features,
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer,
  #portfolio {
    padding: 20px;
    width: 100%; /* Ensure the width is 100% */
    box-sizing: border-box; /* Ensure padding doesn't cause overflow */
  }
}
