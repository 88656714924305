body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

.navbar-header {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 70px; /* Adjust the height to make the logo bigger */
  margin-top: -25px; /* Adjust the margin to move the logo up */
  vertical-align: middle;
}

@media screen and (max-width: 720px) {
  .navbar-logo {
    height: 48px; /* Adjust the height to make the logo bigger */
    margin-top: -13px; /* Adjust the margin to move the logo up */
    vertical-align: middle;
  }
}
#header {
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%; /* Ensure the header takes the full width */
}

@media screen and (max-width: 720px) {
  .intro h1 {
    font-size: 5.5rem; /* Adjust the font size */
  }
  html {
    overflow-x: hidden;
  }
  
}